import React, { useEffect, useState } from "react";
import { Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { 
    ColStyled,
    InfoStyled,
    IntervalRowStyled,
    NoAppointmentsStyled,
    ProfessionalCardStyled, 
    ProfessionalCardTextStyled, 
    ProfessionalCardTitleStyled, 
    SpanStyled
} from './styles'
import 'moment/locale/pt-br'
import Swal from 'sweetalert2'

import AvatarSvg from '../../../assets/icons/avatar.svg'
import InfoSvg from '../../../assets/icons/info.svg'

import api from "../../../utils/api";
import moment from "moment";
import { toast } from "react-toastify";
import CreateAppointmentModal from "../CreateAppointmentModal";

const ProfessionalCalendarCard = ({
    professionalId, 
    healthPlanId, 
    professional, 
    day, 
    clinicId, 
    loadAppointments, 
    getCalendarProfessionals, 
    appointmentType, 
    disableDate
}) => {
    const [procedureId, setProcedureId] = useState()
    const [expanded, setExpanded] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState();
    const [specialty, setSpecialty] = useState();
    const [selectedHour, setSelectedHour] = useState();
    const [personData, setPersonData] = useState();
    const [show, setShow] = useState(false);
    const [canSchedule, setCanSchedule] = useState(true);
    const [dateDisabled, setDateDisabled] = useState(disableDate);

    useEffect(() => {
        setSpecialty(professional?.rqe?.specialtie ?? null)
    }, [professional]);

    useEffect(() => {
        if(personData) createAppointment()
    }, [personData]);

    const selectHour = (index, selected, hour) => {
        if(!expanded && !selected) setExpanded(true)
        if(expanded && selected) setExpanded(false)
        if(!selected){
            setSelectedIndex(index)  
            setSelectedHour(hour)
        } 
        else setSelectedIndex(null)
    }

    async function createAppointment() {
        setCanSchedule(false)
        const start_datetime = day.format('YYYY-MM-DD') + ' ' + moment(selectedHour.hour, 'HH:mm').format('HH:mm:ss');
        const procedure_value = await getItemPrice()
        
        const types = {
            "Consulta":"APPOINTMENT",
            "Exame":"EXAM",
            "Retorno":"RETURN",
            "Teleatendimento":"TELEMEDICINE"
        };

        api.createAppointment({
            personData: personData,
            professional_id: professional.person.id,
            clinic_id: clinicId,
            type: 'Agendamento',
            start_datetime: start_datetime,
            end_datetime: moment(start_datetime, 'YYYY-MM-DD HH:mm:ss').add(selectedHour.duration, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
            status: 'SCHEDULED',
            appointment_items: [
                {
                    item_id: procedureId,
                    item_type: types[`${appointmentType}`],
                    health_plan_id: healthPlanId,
                    value: procedure_value
                }
            ]
        })
        .then(() => {
            Swal.fire({
                title: 'Agendamento concluído!',
                text: `Seu agendamento foi realizado com sucesso. \n Em caso de dúvidas ou cancelamento, entre em contato com a clínica!`,
                icon: 'success',
                confirmButtonText: 'Concluir'
            })
            setShow(false)
            getCalendarProfessionals().then(() => {
                loadAppointments()
            })
            setCanSchedule(true)
        })
        .catch(err => {
            if(err.response.data.errors){
                const errorMessages = Object.values(err.response.data.errors).flat();
                const errorMessage = errorMessages.join(', '); 

                Swal.fire({
                    title: 'Não foi possível fazer o agendamento!',
                    text: errorMessage,
                    icon: 'error',
                    confirmButtonText: 'Concluir'
                });
            } else {
                toast.error("Não foi possível fazer o agendamento!");
            }
            console.log(err)      
        })
    }

    async function getItemPrice() {
        try {
            const res = await api.getItemPrice(clinicId, 'App\\Models\\ClinicProcedure', procedureId, healthPlanId)
            return res.data.price
        } catch (error) {
            toast.error(error.message)
        }        
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {professional.about}
        </Tooltip>
      );

    return (
        <>
            <ProfessionalCardStyled>
                {
                    !professionalId &&
                    <>                        
                        <ProfessionalCardTitleStyled>
                            <img
                                src={professional?.person?.picture ? professional.person.picture : AvatarSvg}
                                width="60px"
                                height="60px"
                                alt="professional"
                            /> 

                            <span>
                                <h2>
                                    Dr{professional.person.gender === 'F' ? 'a. ' +  professional.person.name: '. ' + professional.person.name} 
                                    {
                                        professional.about &&
                                        <OverlayTrigger
                                            placement="right"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={renderTooltip}
                                        >
                                            <InfoStyled
                                                src={InfoSvg}
                                                width="24px"
                                                height="24px"
                                                alt="info"
                                            /> 
                                        </OverlayTrigger>
                                    }
                                </h2>

                                <p>Oftamologista/{specialty ?? 'Geral'} - CRM: {professional.crm.number+'-'+professional.crm.state}</p></span>
                        </ProfessionalCardTitleStyled>
                        
                        <hr />
                    </>
                }

                <ProfessionalCardTextStyled>
                    <h4>Horários disponiveis</h4>
                    

                <IntervalRowStyled>
                    {
                        !dateDisabled ?
                            professional.interval?.length > 0 &&
                            professional.interval
                                .sort((a, b) => {
                                    const timeA = parseInt(a.hour.replace(':', ''), 10);
                                    const timeB = parseInt(b.hour.replace(':', ''), 10);
                                    return timeA - timeB; 
                                })
                                .map((hour, index) => (
                                    <Col xs={4} md={2} lg={2} xl={1}>
                                        <SpanStyled
                                            key={professional.key + '-slot-' + index}
                                            selected={selectedIndex === index}
                                            onClick={() => selectHour(index, selectedIndex === index, hour)}
                                        >
                                            {hour.hour}
                                        </SpanStyled>
                                    </Col>
                                ))
                            :
                            <NoAppointmentsStyled>
                                Nenhum horário disponivel para a data selecionada
                            </NoAppointmentsStyled>
                    }
                </IntervalRowStyled>
                </ProfessionalCardTextStyled>
            </ProfessionalCardStyled>

            {
                expanded &&                
                <Row>
                    <ColStyled>      
                        <CreateAppointmentModal 
                            procedures={professional?.procedures?.filter(procedure => {
                                return procedure.type === appointmentType && procedure.pub_schedule_intervals.find(interval => {
                                    return interval.day === moment(day).weekday()
                                })
                            })}
                            setPersonData={setPersonData}
                            show={show}
                            setShow={setShow}
                            procedureId={procedureId}
                            setProcedureId={setProcedureId}
                            dayNumber={moment(day).weekday()}
                            selectedHour={moment(selectedHour.hour, 'HH:mm:ss')}
                            canSchedule={canSchedule}
                            setCanSchedule={setCanSchedule}
                        />
                    </ColStyled>
                </Row>
            }
        </>
    )
}

export default ProfessionalCalendarCard;